(function () {
  'use strict';

  var serviceId = 'datacontext';
  angular
    .module('app')
    .factory(serviceId, ['$http', 'common', 'config', datacontext]);

  function datacontext($http, common, config) {
    var $q = common.$q;
    var api = config.apiHost + '/api/';
    var odata = config.apiHost + '/odata';

    var getLogFn = common.logger.getLogFn;
    var log = getLogFn(serviceId);

    var service = {
      getWireSizes: getWireSizes,
      getInsideDiameters: getInsideDiameters,
      getOutsideIDs: getOutsideIDs,
      getPitchDefinitions: getPitchDefinitions,
      getCycleDefinitions: getCycleDefinitions,
      getDrumsByLiftTypeAndManufacturerId: getDrumsByLiftTypeAndManufacturerId,
      getNumberOfSprings: getNumberOfSprings,
      getLiftTypes: getLiftTypes,
      getManufacturersByLiftType: getManufacturersByLiftType,
      getTrackRadius: getTrackRadius,
      calculateCommands: calculateCommands,
    };

    return service;

    var tracker = new Array();

    function getNumberOfSprings() {
      var numberOfSprings = [1, 2, 3, 4, 5, 6];
      return $q.when(numberOfSprings);
    }

    function getTrackRadius() {
      var trackRadiuss = ['12', '15', 'LHR'];
      return $q.when(trackRadiuss);
    }

    function getLiftTypes() {
      var liftTypes = ['Standard', 'High', 'Vertical'];
      return $q.when(liftTypes);
    }

    function getWireSizes() {
      var wireSizes = [];
      $http
        .get(odata + '/WireDefinitions')
        .success(function (data, status, headers, config) {
          var results = data.value;
          for (var i = 0; i < results.length; i++) {
            wireSizes.push(results[i].Size);
          }
        })
        .error(function (data, status, headers, config) {
          console.log(data.toString());
        });

      return $q.when(wireSizes);
    }

    function getInsideDiameters() {
      var insideDiameters = [];
      $http
        .get(
          api +
            'diameterdefinitions?roundItems[0].Name=Diameter&roundItems[0].Decimals=3&roundItems[0].MidpointRounding=1'
        )
        .success(function (data, status, headers, config) {
          for (var i = 0; i < data.length; i++) {
            insideDiameters.push(data[i].Diameter);
          }
        })
        .error(function (data, status, headers, config) {
          console.log(data.toString());
        });

      return $q.when(insideDiameters);
    }

    function getOutsideIDs() {
      var OutsideIDs = ['5.25', '5.5', '5.875', '6'];
      return $q.when(OutsideIDs);
    }

    function getPitchDefinitions() {
      var deferred = $q.defer();
      $http
        .get(odata + '/PitchDefinitions')
        .success(querySucceeded)
        .error(function (data, status, headers, config) {
          common.logger.logError(data.toString(), '', '', true);
        });

      function querySucceeded(data) {
        var pitchDefinitions = [];
        var results = data.value;
        for (var i = 0; i < results.length; i++) {
          pitchDefinitions.push({
            pitchDefinitionId: results[i].Id,
            pitchDefinitionDescription: results[i].Description,
          });
        }
        deferred.resolve(pitchDefinitions);
      }
      return deferred.promise;
    }

    function getManufacturersByLiftType(liftType) {
      var deferred = $q.defer();
      $http
        .get(
          odata +
            '/Drums?$filter=LiftType eq %27' +
            liftType +
            '%27&$expand=Manufacturer($select=Name,Id)&$orderby=Manufacturer/Name&$select=Manufacturer'
        )
        .success(querySucceeded)
        .error(function (data, status, headers, config) {
          common.logger.logError(data.toString(), '', '', true);
        });

      function querySucceeded(data) {
        var manufacturers = [];
        var results = data.value;
        for (var i = 0; i < results.length; i++) {
          manufacturers.push({
            id: results[i].Manufacturer.Id,
            description: results[i].Manufacturer.Name,
          });
        }
        deferred.resolve(manufacturers);
      }
      return deferred.promise;
    }

    function getDrumsByLiftTypeAndManufacturerId(liftType, manufacturerId) {
      var deferred = $q.defer();
      $http
        .get(
          odata +
            '/Drums?$expand=Manufacturer($select=Id)&$filter=(LiftType eq %27' +
            liftType +
            '%27) and (Manufacturer/Id eq ' +
            manufacturerId +
            ')&$orderby=Number'
        )
        .success(querySucceeded)
        .error(function (data, status, headers, config) {
          common.logger.logError(data.toString(), '', '', true);
        });

      function querySucceeded(data) {
        var drums = [];
        var results = data.value;
        for (var i = 0; i < results.length; i++) {
          drums.push(results[i]);
        }
        deferred.resolve(drums);
      }
      return deferred.promise;
    }

    function getCycleDefinitions() {
      var cycleDefinitions = [];
      $http
        .get(odata + '/CycleDefinitions?$select=Id,Value')
        .success(function (data, status, headers, config) {
          var results = data.value;
          for (var i = 0; i < results.length; i++) {
            cycleDefinitions.push(results[i].Value);
          }
        })
        .error(function (data, status, headers, config) {
          common.logger.logError(data.toString(), '', '', true);
        });

      return $q.when(cycleDefinitions);
    }

    function calculateCommands(transactionId, commands) {
      //clear out existing messaages
      common.logger.clearToast();

      var request = { TransactionId: transactionId, Commands: commands };
      var deferred = $q.defer();

      $http
        .post(api + 'Commands', request, {
          headers: { 'Content-Type': 'application/json' },
        })
        .success(function (data, status, headers, config) {
          var message = '';
          var element;
          if (
            data &&
            data.Commands &&
            data.Commands.length > 0 &&
            data.Commands[0].Command &&
            data.Commands[0].Command.Result &&
            data.Commands[0].Command.Result.AdditionalMessages &&
            data.Commands[0].Command.Result.AdditionalMessages.length > 0 &&
            data.Commands[0].Command.Result.AdditionalMessages[0] !== null
          ) {
            message =
              data.Commands[0].Command.Result.AdditionalMessages[0].Message;
            element =
              data.Commands[0].Command.Result.AdditionalMessages[0]
                .FailureAttributeKey;
            common.logger.logWarning(message, '', '', true);
          }
          deferred.resolve(data);
        })
        .error(function (data, status, headers, config) {
          var message = '';
          var element;
          if (
            data &&
            data.Commands &&
            data.Commands.length > 0 &&
            data.Commands[0].Command &&
            data.Commands[0].Command.Result
          ) {
            message = data.Commands[0].Command.Result.Message;
            element = data.Commands[0].Command.Result.FailureAttributeKey;
          }
          common.logger.logError(message, '', '', true);
          deferred.reject(data, status, headers, config);
        });

      return deferred.promise;
    }
  }
})();
