(function () {
    'use strict';

    var controllerId = 'springengineeringduplex';

    angular
        .module('app')
        .controller(controllerId, ['$scope', 'common', 'datacontext', 'springengineeringservice', springengineeringduplex]);

    function springengineeringduplex($scope, common, datacontext, springengineeringservice) {
        var vm = this;
        var getLogFn = common.logger.getLogFn;
        var log = getLogFn(controllerId);


        var roundInnerWireSize = { decimals: 4, midpointRounding: 0 };
        var roundInnerLength = { decimals: 2, midpointRounding: 0 };
        var roundInnerWeight = { decimals: 2, midpointRounding: 0 };
        var roundInnerInsideDiameter = { decimals: 2, midpointRounding: 0 };
        var roundOuterInsideDiameter = { decimals: 2, midpointRounding: 0 };
        var roundOuterWireSize = { decimals: 4, midpointRounding: 0 };
        var roundOuterLength = { decimals: 2, midpointRounding: 0 };
        var roundOuterWeight = { decimals: 2, midpointRounding: 0 };

        var roundOpenWeight = { decimals: 2, midpointRounding: 0 };
        var roundTurns = { decimals: 2, midpointRounding: 0 };
        var roundMIP = { decimals: 2, midpointRounding: 0 };
        var roundPercentOfMTS = { decimals: 2, midpointRounding: 0 };
        var roundIPPTPerSpring = { decimals: 2, midpointRounding: 0 };

        var contentType = 'Spring.Application.Commands.CommandCalculateSpringEngineeringDuplex';

        vm.activate = activate;
        vm.title = 'Spring Engineering-Duplex';
        vm.headerText = 'Engineering - Duplex';
        vm.springIdLabel = 'Outer Spring I.D.';
        springengineeringservice.initializeModel(vm);
        activate();

        function activate() {
            var promises = [getPitchDefinitions(), getOutsideIDs(), getNumberOfSprings(), getLiftTypes(), getTrackRadius()];
            common.activateController(promises, controllerId)
                .then(function () {
                    log('Activated Duplex Engineering Calculator View');
                    common.logger.logWarning("Please verify that there is enough shaft space for the designed spring(s).", '', '', true);
                });
        }

        function getPitchDefinitions() {
            return datacontext.getPitchDefinitions().then(function (data) {
                return vm.pitchDefinitions = data;
            });
        }

        function getCycleDefinitions() {
            return datacontext.getCycleDefinitions().then(function (data) {
                return vm.cycleDefinitions = data;
            });
        }

        function getOutsideIDs() {
            return datacontext.getOutsideIDs().then(function (data) {
                return vm.insideDiameters = data;
            });
        }

        function getNumberOfSprings() {
            return datacontext.getNumberOfSprings().then(function (data) {
                return vm.numberOfSprings = data;
            });
        }

        function getTrackRadius() {
            return datacontext.getTrackRadius().then(function (data) {
                return vm.trackRadiuss = data;
            });
        }

        function getLiftTypes() {
            return datacontext.getLiftTypes().then(function (data) {
                return vm.liftTypes = data;
            });
        }

        vm.getManufacturersByLiftType = function () {
            return springengineeringservice.getManufacturersByLiftType(vm);
        }

        vm.isManufacturersEnabled = function () {
            return (vm.manufacturers && vm.manufacturers.length > 0);
        }

        vm.getDrumsByLiftTypeAndManufacturerId = function () {
            vm.loadingDrums = true;
            vm.loadingDrumsText = 'Loading...';
            vm.drumsLoaded = false;
            springengineeringservice.getDrumsByLiftTypeAndManufacturerId(vm.input.liftType, vm.input.manufacturerId)
            .then(function (data) {
                vm.input.drum = null;
                vm.drums = data;
                vm.drumsLoaded = true;
            }).finally(function () {
                vm.loadingDrums = false;
                vm.loadingDrumsText = '';
            });
        };

        // watch replacement spring inputs to do calculation
        $scope.$watchCollection('vm.input', function (newReplacementSpring, oldReplacementSpring) {
            if (springengineeringservice.isCalculationReady(vm, newReplacementSpring, oldReplacementSpring)) {
                calculateEngineeringDuplex();
            }
        });

        function calculateEngineeringDuplex() {
            var commands = [];
            var command = {};

            command.manufacturerId = vm.input.manufacturerId;
            command.drumId = vm.input.drum.Id;
            command.trackRadius = vm.input.trackRadius;

            command.width = vm.input.width;
            command.height = vm.input.height;
            command.weight = vm.input.weight;

            command.pitchDefinitionId = vm.input.pitchDefinitionId;
            command.highLift = vm.input.highLift;

            command.numberOfSprings = vm.input.numberOfSprings;
            command.outerInsideDiameter = vm.input.insideDiameter;
            command.cycles = vm.input.cycles;

            command.roundInnerWireSize = roundInnerWireSize;
            command.roundInnerLength = roundInnerLength;
            command.roundInnerWeight = roundInnerWeight;
            command.roundInnerInsideDiameter = roundInnerInsideDiameter;
            command.roundOuterInsideDiameter = roundOuterInsideDiameter;
            command.roundOuterWireSize = roundOuterWireSize;
            command.roundOuterLength = roundOuterLength;
            command.roundOuterWeight = roundOuterWeight;


            command.roundOpenWeight = roundOpenWeight;
            command.roundTurns = roundTurns;
            command.roundMIP = roundMIP;
            command.roundPercentOfMTS = roundPercentOfMTS;
            command.roundIPPTPerSpring = roundIPPTPerSpring;

            commands.push({ ContentType: contentType, Command: command });
            var transactionId = common.generateGuid();

            datacontext.calculateCommands(transactionId, commands)
             .then(function (data) {
                 mapData(data);
             }, function (data, status, headers, config) {
                 console.log(element);
                 vm.result = {};
             })
            .finally(function () {
                vm.calculationsComplete = 'true';
            });


            function mapData(data) {
                var result = data.Commands[0].Command.Result;
                vm.result.innerWireSize = result.InnerWireSize;
                vm.result.innerInsideDiameter = result.InnerInsideDiameter;
                vm.result.innerLength = result.InnerLength;
                vm.result.ipptPerSpring = result.IPPTPerSpring;
                vm.result.turns = result.Turns;

                vm.result.outerWireSize = result.OuterWireSize;
                vm.result.outerInsideDiameter = result.OuterInsideDiameter;
                vm.result.outerLength = result.OuterLength;

                vm.result.minDoorWidth = result.MinDoorWidth;
            };
        }
    }
})();
