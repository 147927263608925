(function () {
    'use strict';

    angular
        .module('app')
        .directive('springSelectInsideDiameters', springSelectInsideDiameters);

    function springSelectInsideDiameters() {
        return {
            restrict: 'E',
            scope: {
                selectedvalue: '='
            },
            templateUrl: '/app/_directives/select/springselectcustom.html',
            controller: DatasourceController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    DatasourceController.$inject = ['domainService'];
    function DatasourceController(domainService) {
        var vm = this;
        vm.datasource = domainService.getInsideDiameters();
    }
}());