(function () {
    'use strict';

    var serviceId = 'springengineeringservice';
    angular.module('app').factory(serviceId, ['$q', 'common', 'datacontext', springengineeringservice]);

    function springengineeringservice($q, common, datacontext) {
        var service = {
            isCalculationReady: isCalculationReady,
            areInputsComplete: areInputsComplete,
            initializeModel: initializeModel,
            getManufacturersByLiftType: getManufacturersByLiftType,
            getDrumsByLiftTypeAndManufacturerId: getDrumsByLiftTypeAndManufacturerId
        };
        return service;

        function getManufacturersByLiftType(vm) {
            var deferred = $q.defer();
            vm.loadingManufacturers = true;
            vm.loadingManufacturersText = 'Loading...';
            vm.manufacturersLoaded = false;
            vm.drumsLoaded = false;
            datacontext.getManufacturersByLiftType(vm.input.liftType)
                .then(function (data) {
                    var uniqueManufacturers = _.uniq(data, true, function (item) {
                        return item.id;
                    });
                    vm.drums = {};
                    vm.input.drum = null;
                    vm.input.manufacturerId = null;
                    vm.manufacturers = uniqueManufacturers;
                    vm.manufacturersLoaded = true;
                }).finally(function () {
                    vm.loadingManufacturers = false;
                    vm.loadingManufacturersText = '';
                });

            return deferred.promise;
        };

        function getDrumsByLiftTypeAndManufacturerId(liftType, manufacturerId) {
            var deferred = $q.defer();
            datacontext.getDrumsByLiftTypeAndManufacturerId(liftType, manufacturerId).then(function (data) {
                deferred.resolve(data);
            });
            return deferred.promise;
        };

        function isCalculationReady(vm, newSpring, oldSpring) {
            if (areInputsComplete(vm)) {
                return (newSpring != oldSpring);
            } else {
                common.clearResults(vm.result);
                vm.calculationsComplete = 'false';
                return false;
            }
            return false;
        }

        function areInputsComplete(vm) {
            if (
                vm.input.manufacturerId != null &&
                vm.input.drum != null &&
                vm.input.width != null &&
                vm.input.height != null &&
                vm.input.weight != null &&
                vm.input.numberOfSprings != null &&
                vm.input.insideDiameter != null &&
                vm.input.cycles != null &&
                isTrackRadiusValid(vm)
            )
                return true;
            else
                return false;
        }

        //there is a little extra logic around whether this field is required
        function isTrackRadiusValid(vm) {
            if (!vm.input.liftType) {
                return false;
            }
            else {
                if (vm.input.liftType != 'Vertical') {
                    return vm.input.trackRadius != null;
                }
                else {
                    return true;
                }
            }
        }

        function initializeModel(vm) {

            vm.result = {};
            vm.input = {};

            vm.input.manufacturerId = null;
            vm.input.drum = null;
            vm.input.trackRadius = null;

            vm.input.width = null;
            vm.input.height = null;
            vm.input.weight = null;

            vm.input.pitchDefinitionId = null;
            vm.input.highLift = null;

            vm.input.numberOfSprings = null;
            vm.input.insideDiameter = null;
            vm.input.cycles = null;

            vm.calculationsComplete = 'false';
        }
    }

})();