(function () {
    'use strict';

    angular
        .module('app')
        .factory('domainService', domainService);

    domainService.$inject = ['datacontext', 'logger'];
    function domainService(datacontext, logger) {
        var vm = this;
        vm.numberOfSprings = null;
        vm.wireSizes = null;
        vm.insideDiameters = null;
        vm.cycles = null;

        var service = {
            prime: prime,
            getNumberOfSprings: getNumberOfSprings,
            getWireSizes: getWireSizes,
            getInsideDiameters: getInsideDiameters,
            getCycles: getCycles
        }

        return service;



        function prime() {
            logger.log('Priming Data');
            setNumberOfSprings();
            setWireSizes();
            setInsideDiameters();
            setCycles();
        }

        function getCycles() {
            if (!vm.cycles) {
                setCycles();
            }
            return vm.cycles;
        }

        function setCycles() {
            return datacontext.getCycleDefinitions().then(function (data) {
                return vm.cycles = data;
            });
        }

        function getNumberOfSprings() {
            if (!vm.numberOfSprings) {
                setNumberOfSprings();
            }
            return vm.numberOfSprings;
        }
        function setNumberOfSprings() {
            return datacontext.getNumberOfSprings().then(function (data) {
                return vm.numberOfSprings = data;
            });
        }


        function getWireSizes() {
            if (!vm.wireSizes) {
                setWireSizes();
            }
            return vm.wireSizes;
        }
        function setWireSizes() {
            return datacontext.getWireSizes().then(function (data) {
                return vm.wireSizes = data;
            });
        }


        function getInsideDiameters() {
            if (!vm.insideDiameters) {
                setInsideDiameters();
            }
            return vm.insideDiameters;
        }
        function setInsideDiameters() {
            return datacontext.getInsideDiameters().then(function (data) {
                return vm.insideDiameters = data;
            });
        }

    }

})();