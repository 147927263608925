(function () {
    'use strict';

    var controllerId = 'rollingsteel';

    angular
        .module('app')
        .controller(controllerId, ['$scope', 'common', 'datacontext', rollingsteel]);

    function rollingsteel($scope, common, datacontext) {
        var vm = this;
        var getLogFn = common.logger.getLogFn;
        var log = getLogFn(controllerId);


        var roundWireSize = { decimals: 4, midpointRounding: 0 };
        var roundLength = { decimals: 2, midpointRounding: 0 };
        var roundWeight = { decimals: 2, midpointRounding: 0 };
        var roundTurnsRequired = { decimals: 2, midpointRounding: 0 };
        var roundInitialTurns = { decimals: 2, midpointRounding: 0 };
        var roundMinPrestretch = { decimals: 2, midpointRounding: 0 };
        var roundIPPTPerSpring = { decimals: 2, midpointRounding: 0 };

        var contentType = 'Spring.Application.Commands.CommandCalculateRollingSteelDoorSpring';

        initializeModel();
        activate();

        function activate() {
            var promises = [getInsideDiameters()];
            common.activateController(promises, controllerId)
                .then(function () {
                    log('Activated Rolling Steel Calculator View');
                    common.logger.logWarning("Please verify that there is enough shaft space for the designed spring(s).", '', '', true);
                });
        }

        function getInsideDiameters() {
            return datacontext.getInsideDiameters().then(function (data) {
                return vm.insideDiameters = data;
            });
        }


        // watch replacement spring inputs to do calculation
        $scope.$watchCollection('vm.input', function (newReplacementSpring, oldReplacementSpring) {
            if (areInputsComplete(newReplacementSpring)) {
                calculateRollingSteel();
            }
        });

        function areInputsComplete(newSpring, oldSpring) {
            if (common.areInputsComplete(newSpring)) {
                return (newSpring != oldSpring);
            } else {
                common.clearResults(vm.result);
                vm.calculationsComplete = 'false';
                return false;
            }
            return false;
        }


        function calculateRollingSteel() {
            var commands = [];
            var command = {};
            command.numberOfSprings = vm.input.numberOfSprings;
            command.insideDiameter = vm.input.insideDiameter;
            command.cycles = vm.input.cycles;
            command.lowMomentArm = vm.input.lowMomentArm;
            command.highMomentArm = vm.input.highMomentArm;
            command.totalWeight = vm.input.totalWeight;
            command.openWeight = vm.input.openWeight;
            command.revOfBarrel = vm.input.revOfBarrel;
            command.roundWireSize = roundWireSize;
            command.roundLength = roundLength;
            command.roundWeight = roundWeight;
            command.roundTurnsRequired = roundTurnsRequired;
            command.roundInitialTurns = roundInitialTurns;
            command.roundMinPrestretch = roundMinPrestretch;
            command.roundIPPTPerSpring = roundIPPTPerSpring;
            commands.push({ ContentType: contentType, Command: command });
            var transactionId = common.generateGuid();

            return datacontext.calculateCommands(transactionId, commands).then(function (data) {
                mapData(data);

                vm.calculationsComplete = 'true';
            });


            function mapData(data) {
                var result = data.Commands[0].Command.Result;
                vm.result.wireSize = result.WireSize;
                vm.result.length = result.Length;
                vm.result.weight = result.Weight;

                vm.result.turnsRequired = result.TurnsRequired;
                vm.result.ipptSpring = result.IPPTPerSpring;
                vm.result.initialTurns = result.InitialTurns;

                vm.result.minPrestretch = result.MinPrestretch;
            };
        }

        function initializeModel() {
            vm.activate = activate;
            vm.title = 'Rolling Steel';
            vm.insideDiameters = [];
            vm.result = {};
            vm.input = {};
            vm.input.numberOfSprings = null;
            vm.input.insideDiameter = null;
            vm.input.cycles = null;
            vm.input.lowMomentArm = null;
            vm.input.highMomentArm = null;
            vm.input.totalWeight = null;
            vm.input.openWeight = null;
            vm.input.revOfBarrel = null;
            vm.calculationsComplete = 'false';
        }
    }
})();
