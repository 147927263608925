(function () {
    'use strict';

    angular
    .module('app')
    .directive('springInput', springInput);

    function springInput() {
        return {
            restrict: 'E',
            scope: {
                datavalue: '='
            },
            templateUrl: '/app/_directives/springinput.html'
        }
    }
}());