(function () {
    'use strict';

    var controllerId = 'springengineeringsingle';

    angular
        .module('app')
        .controller(controllerId, ['$scope', 'common', 'datacontext', 'springengineeringservice', springengineeringsingle]);

    function springengineeringsingle($scope, common, datacontext, springengineeringservice) {
        var vm = this;
        var getLogFn = common.logger.getLogFn;
        var log = getLogFn(controllerId);


        var roundWireSize = { decimals: 4, midpointRounding: 0 };
        var roundLength = { decimals: 2, midpointRounding: 0 };
        var roundWeight = { decimals: 2, midpointRounding: 0 };
        var roundOpenWeight = { decimals: 2, midpointRounding: 0 };
        var roundTurns = { decimals: 2, midpointRounding: 0 };
        var roundMIP = { decimals: 2, midpointRounding: 0 };
        var roundPercentOfMTS = { decimals: 2, midpointRounding: 0 };
        var roundIPPTPerSpring = { decimals: 2, midpointRounding: 0 };

        var contentType = 'Spring.Application.Commands.CommandCalculateSpringEngineeringSingle';

        vm.activate = activate;
        vm.title = 'Spring Engineering-Single';
        vm.headerText = 'Engineering - Single';
        vm.springIdLabel = 'Spring I.D.';
        springengineeringservice.initializeModel(vm);
        activate();

        function activate() {
            var promises = [getPitchDefinitions(), getInsideDiameters(), getNumberOfSprings(), getLiftTypes(), getTrackRadius()];
            common.activateController(promises, controllerId)
                .then(function () {
                    log('Activated Single Engineering Calculator View');
                    common.logger.logWarning("Please verify that there is enough shaft space for the designed spring(s).", '', '', true);
                });
        }

        function getPitchDefinitions() {
            return datacontext.getPitchDefinitions().then(function (data) {
                return vm.pitchDefinitions = data;
            });
        }

        function getCycleDefinitions() {
            return datacontext.getCycleDefinitions().then(function (data) {
                return vm.cycleDefinitions = data;
            });
        }

        function getInsideDiameters() {
            return datacontext.getInsideDiameters().then(function (data) {
                return vm.insideDiameters = data;
            });
        }

        function getNumberOfSprings() {
            return datacontext.getNumberOfSprings().then(function (data) {
                return vm.numberOfSprings = data;
            });
        }

        function getTrackRadius() {
            return datacontext.getTrackRadius().then(function (data) {
                return vm.trackRadiuss = data;
            });
        }

        function getLiftTypes() {
            return datacontext.getLiftTypes().then(function (data) {
                return vm.liftTypes = data;
            });
        }

        vm.getManufacturersByLiftType = function () {
            return springengineeringservice.getManufacturersByLiftType(vm);
        }

        vm.getDrumsByLiftTypeAndManufacturerId = function () {
            vm.loadingDrums = true;
            vm.loadingDrumsText = 'Loading...';
            vm.drumsLoaded = false;
            springengineeringservice.getDrumsByLiftTypeAndManufacturerId(vm.input.liftType, vm.input.manufacturerId)
            .then(function (data) {
                vm.input.drum = null;
                vm.drums = data;
                vm.drumsLoaded = true;
            }).finally(function () {
                vm.loadingDrums = false;
                vm.loadingDrumsText = '';
            });
        };

        vm.isManufacturersEnabled = function () {
            return (vm.manufacturers && vm.manufacturers.length > 0);
        }

        // watch replacement spring inputs to do calculation
        $scope.$watchCollection('vm.input', function (newReplacementSpring, oldReplacementSpring) {
            if (springengineeringservice.isCalculationReady(vm, newReplacementSpring, oldReplacementSpring)) {
                calculateEngineeringSingle();
            }

        });

        function calculateEngineeringSingle() {
            var commands = [];
            var command = {};

            command.manufacturerId = vm.input.manufacturerId;
            command.drumId = vm.input.drum.Id;
            command.trackRadius = vm.input.trackRadius;

            command.width = vm.input.width;
            command.height = vm.input.height;
            command.weight = vm.input.weight;

            command.pitchDefinitionId = vm.input.pitchDefinitionId;
            command.highLift = vm.input.highLift;

            command.numberOfSprings = vm.input.numberOfSprings;
            command.insideDiameter = vm.input.insideDiameter;
            command.cycles = vm.input.cycles;

            command.roundWireSize = roundWireSize;
            command.roundLength = roundLength;
            command.roundWeight = roundWeight;
            command.roundOpenWeight = roundOpenWeight;
            command.roundTurns = roundTurns;
            command.roundMIP = roundMIP;
            command.roundPercentOfMTS = roundPercentOfMTS;
            command.roundIPPTPerSpring = roundIPPTPerSpring;

            commands.push({ ContentType: contentType, Command: command });
            var transactionId = common.generateGuid();

            datacontext.calculateCommands(transactionId, commands)
             .then(function (data) {
                 mapData(data);
             }, function (data, status, headers, config) {
                 console.log(element);
                 vm.result = {};
             })
            .finally(function () {
                vm.calculationsComplete = 'true';
            });


            function mapData(data) {
                vm.result.wireSize = data.Commands[0].Command.Result.WireSize;
                vm.result.insideDiameter = data.Commands[0].Command.Result.InsideDiameter;
                vm.result.length = data.Commands[0].Command.Result.Length;
                vm.result.ipptPerSpring = data.Commands[0].Command.Result.IPPTPerSpring;
                vm.result.turns = data.Commands[0].Command.Result.Turns;
            };
        }
    }
})();
