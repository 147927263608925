(function () {
    'use strict';

    var controllerId = 'mismatchedsprings';

    angular
        .module('app')
        .controller(controllerId, ['$scope', 'common', 'datacontext', mismatchedsprings]);

    function mismatchedsprings($scope, common, datacontext) {
        var vm = this;
        var getLogFn = common.logger.getLogFn;
        var log = getLogFn(controllerId);

        initializeModel();
        activate();

        function activate() {
            var promises = [getWireSizes(), getInsideDiameters()];
            common.activateController(promises, controllerId)
                .then(function () {
                    log('Activated Mismatched Springs Calculator View');
                    common.logger.logWarning("Please verify that there is enough shaft space for the designed spring(s).", '', '', true);
                });
        }

        function getWireSizes() {
            return datacontext.getWireSizes().then(function (data) {
                return vm.wireSizes = data;
            });
        }

        function getInsideDiameters() {
            return datacontext.getInsideDiameters().then(function (data) {
                return vm.insideDiameters = data;
            });
        }

        // watch existing spring inputs to do calculation
        $scope.$watchCollection('vm.existingSpring1.input', function (newExistingSpring, oldExistingSpring) {
            if (areExistingSpring1InputsComplete(newExistingSpring, oldExistingSpring)) {
                calculateExistingSpring(vm.existingSpring1);
                if (areReplacementSpringInputsComplete(vm.replacementSpring.input)) {
                    calculateMismatchedSprings();
                }
            }
        });

        // watch existing spring inputs to do calculation
        $scope.$watchCollection('vm.existingSpring2.input', function (newExistingSpring, oldExistingSpring) {
            if (areExistingSpring2InputsComplete(newExistingSpring, oldExistingSpring)) {
                calculateExistingSpring(vm.existingSpring2);
                if (areReplacementSpringInputsComplete(vm.replacementSpring.input)) {
                    calculateMismatchedSprings();
                }
            }
        });

        // watch replacement spring inputs to do calculation
        $scope.$watchCollection('vm.replacementSpring.input', function (newReplacementSpring, oldReplacementSpring) {
            if (areReplacementSpringInputsComplete(newReplacementSpring)) {
                calculateMismatchedSprings();
            }
        });

        function areExistingSpring1InputsComplete(newSpring) {
            if (common.areInputsComplete(newSpring)) {
                return true;
            } else {
                common.clearResults(vm.existingSpring1.result);
                common.clearResults(vm.replacementSpring.result);
                vm.calculationsComplete = false;
                return false;
            }
            return false;
        }

        function areExistingSpring2InputsComplete(newSpring) {
            if (common.areInputsComplete(newSpring)) {
                return true;
            } else {
                common.clearResults(vm.existingSpring2.result);
                common.clearResults(vm.replacementSpring.result);
                vm.calculationsComplete = false;
                return false;
            }
            return false;
        }

        function areReplacementSpringInputsComplete(newSpring, oldSpring) {
            if (common.areInputsComplete(newSpring)) {
                return (newSpring != oldSpring);
            } else {
                common.clearResults(vm.replacementSpring.result);
                vm.calculationsComplete = false;
                return false;
            }
            return false;
        }

        function calculateExistingSpring(existingSpring) {
            var commands = [];
            var command = {};
            var contentType = 'Spring.Application.Commands.CommandCalculateMatchedSpring';
            var transactionId = common.generateGuid();

            command.existingSpring = existingSpring.input;
            command.roundExistingSpring = vm.roundExistingSpring;
            commands.push({ ContentType: contentType, Command: command });

            return datacontext.calculateCommands(transactionId, commands).then(function (data) {
                mapData(data);
            });

            function mapData(data) {
                var command = data.Commands[0].Command;
                existingSpring.result.maxTurns = command.Result.ExistingSpring.MaxTurns;
                existingSpring.result.inchPoundsPerTurn = command.Result.ExistingSpring.InchPoundsPerTurn;
                existingSpring.result.cycles = command.Result.ExistingSpring.Cycles;
            }
        }

        function calculateMismatchedSprings() {
            var commands = [];
            var command = {};
            var contentType = 'Spring.Application.Commands.CommandCalculateMismatchedSprings';
            var transactionId = common.generateGuid();

            command.existingSpring_1 = vm.existingSpring1.input;
            command.existingSpring_2 = vm.existingSpring2.input;
            command.replacementSpring = vm.replacementSpring.input;
            command.roundExistingSpring_1 = vm.roundExistingSpring;
            command.roundExistingSpring_2 = vm.roundExistingSpring;
            command.roundReplacementSpring = vm.roundReplacementSpring;
            command.roundMinDoorWidth = vm.roundMinDoorWidth;
            commands.push({ ContentType: contentType, Command: command });

            return datacontext.calculateCommands(transactionId, commands).then(function (data) {
                mapData(data);

                vm.calculationsComplete = true;
            });

            function mapData(data) {
                var result = data.Commands[0].Command.Result;

                vm.existingSpring1.result.maxTurns = result.ExistingSpring_1.MaxTurns;
                vm.existingSpring1.result.inchPoundsPerTurn = result.ExistingSpring_1.InchPoundsPerTurn;
                vm.existingSpring1.result.cycles = result.ExistingSpring_1.Cycles;
                vm.existingSpring2.result.maxTurns = result.ExistingSpring_2.MaxTurns;
                vm.existingSpring2.result.inchPoundsPerTurn = result.ExistingSpring_2.InchPoundsPerTurn;
                vm.existingSpring2.result.cycles = result.ExistingSpring_2.Cycles;
                vm.replacementSpring.result.maxTurns = result.ReplacementSpring.MaxTurns;
                vm.replacementSpring.result.inchPoundsPerTurn = result.ReplacementSpring.InchPoundsPerTurn;
                vm.replacementSpring.result.cycles = result.ReplacementSpring.Cycles;
                vm.replacementSpring.result.length = result.ReplacementSpring.Length;
                vm.replacementSpring.result.minDoorWidth = result.MinDoorWidth;
            }
        }

        vm.showReplacementSpringClick = function () {
            vm.showReplacementSpring = 'true';
            vm.showExistingSpring = 'false';
        }

        vm.showExistingSpringClick = function () {
            vm.showReplacementSpring = 'false';
            vm.showExistingSpring = 'true';
        }

        function initializeModel() {
            vm.activate = activate;
            vm.title = 'Mismatched Springs';
            vm.showExistingSpring = 'true';
            vm.showReplacementSpring = 'false';
            vm.existingSpring1 = {};
            vm.existingSpring1.result = {};
            vm.existingSpring1.input = {};
            vm.existingSpring1.input.numberOfSprings = null;
            vm.existingSpring1.input.wireSize = null;
            vm.existingSpring1.input.insideDiameter = null;
            vm.existingSpring1.input.length = null;
            vm.existingSpring2 = {};
            vm.existingSpring2.result = {};
            vm.existingSpring2.input = {};
            vm.existingSpring2.input.numberOfSprings = null;
            vm.existingSpring2.input.wireSize = null;
            vm.existingSpring2.input.insideDiameter = null;
            vm.existingSpring2.input.length = null;
            vm.replacementSpring = {};
            vm.replacementSpring.result = {};
            vm.replacementSpring.input = {};
            vm.replacementSpring.input.numberOfSprings = null;
            vm.replacementSpring.input.wireSize = null;
            vm.replacementSpring.input.insideDiameter = null;
            vm.calculationsComplete = false;
            vm.roundExistingSpring = { roundInchPoundsPerTurn: { decimals: 2, midpointRounding: 0 }, roundMaxTurns: { decimals: 2, midpointRounding: 0 }, roundCycles: { decimals: 2, midpointRounding: 0 } };
            vm.roundReplacementSpring = { roundLength: { decimals: 2, midpointRounding: 0 }, roundInchPoundsPerTurn: { decimals: 2, midpointRounding: 0 }, roundMaxTurns: { decimals: 2, midpointRounding: 0 }, roundCycles: { decimals: 2, midpointRounding: 0 } };
            vm.roundMinDoorWidth = { decimals: 2, midpointRounding: 0 };
        }
    }
})();
