(function () {
    'use strict';

    var controllerId = 'replacementoptions';

    angular
        .module('app')
        .controller(controllerId, replacementoptions);

    replacementoptions.$inject = ['$scope', 'common', 'datacontext', 'domainService'];
    function replacementoptions($scope, common, datacontext, domainService) {
        var vm = this;
        var getLogFn = common.logger.getLogFn;
        var log = getLogFn(controllerId);

        initializeModel();
        activate();

        function activate() {
            var promises = [];
            common.activateController(promises, controllerId)
                .then(function () {
                    log('Activated Replacement Options Calculator View');
                    common.logger.logWarning("Please verify that there is enough shaft space for the designed spring(s).", '', '', true);
                });
        }

        // watch existing spring inputs to do calculation
        $scope.$watchCollection('vm.existingSpring.input', function (newExistingSpring, oldExistingSpring) {
            if (areExistingSpringInputsComplete(newExistingSpring, oldExistingSpring)) {
                calculateExistingSpring();
                if (areReplacementSpringInputsComplete(vm.replacementSpring.input)) {
                    calculateReplacementSprings();
                }
            }
        });

        // watch replacement spring inputs to do calculation
        $scope.$watchCollection('vm.replacementSpring.input', function (newReplacementSpring, oldReplacementSpring) {
            if (areReplacementSpringInputsComplete(newReplacementSpring)) {
                calculateReplacementSprings();
            }
        });


        function areExistingSpringInputsComplete(newSpring) {
            if (common.areInputsComplete(newSpring)) {
                return true;
            } else {
                common.clearResults(vm.existingSpring.result);
                common.clearResults(vm.replacementSpring.result);
                vm.calculationsComplete = false;
                return false;
            }
            return false;
        }

        function areReplacementSpringInputsComplete(newSpring, oldSpring) {
            if (common.areInputsComplete(newSpring)) {
                return (newSpring != oldSpring);
            } else {
                common.clearResults(vm.replacementSpring.result);
               vm.calculationsComplete = false;
                return false;
            }
            return false;
        }

        function calculateExistingSpring() {
            var commands = [];
            var command = {};
            var contentType = 'Spring.Application.Commands.CommandCalculateMatchedSpring';

            command.existingSpring = vm.existingSpring.input;
            command.roundExistingSpring = vm.roundExistingSpring;
            commands.push({ ContentType: contentType, Command: command });
            var transactionId = common.generateGuid();

            return datacontext.calculateCommands(transactionId, commands).then(function (data) {
                mapData(data);
            });

            function mapData(data) {
                var command = data.Commands[0].Command;
                vm.existingSpring.result.maxTurns = command.Result.ExistingSpring.MaxTurns;
                vm.existingSpring.result.inchPoundsPerTurn = command.Result.ExistingSpring.InchPoundsPerTurn;
                vm.existingSpring.result.cycles = command.Result.ExistingSpring.Cycles;
            }
        }

        function calculateReplacementSprings() {
            var commands = [];
            var contentType = 'Spring.Application.Commands.CommandCalculateMatchedSprings';
            var transactionId = common.generateGuid();


            var wireSizeIdx = vm.wireSizes.indexOf(vm.existingSpring.input.wireSize);
            for (var i = 0, j = wireSizeIdx; i < 5 && j < vm.wireSizes.length; i++, j++) {
                (function (i, j) {
                    var command = {};
                    command.replacementSpring = {};
                    command.existingSpring = vm.existingSpring.input;
                    command.roundExistingSpring = vm.roundExistingSpring;
                    command.roundReplacementSpring = vm.roundReplacementSpring;
                    command.roundMinDoorWidth = vm.roundMinDoorWidth;

                    command.replacementSpring.insideDiameter = vm.replacementSpring.input.insideDiameter;
                    command.replacementSpring.numberOfSprings = vm.replacementSpring.input.numberOfSprings;
                    command.replacementSpring.wireSize = vm.wireSizes[j];
                    commands.push({ ContentType: contentType, Command: command });
                })(i, j);
            };
            return datacontext.calculateCommands(transactionId, commands).then(function (data) {
                vm.replacementSpring.results = mapData(data);

                vm.calculationsComplete = true;
            });

            function mapData(data) {
                var results = []

                for (var i = 0; i < data.Commands.length; i++) {
                    var command = data.Commands[i].Command;
                    results[i] = {};
                    results[i].result = {};

                    results[i].result.wireSize = command.ReplacementSpring.WireSize;
                    results[i].result.maxTurns = command.Result.ReplacementSpring.MaxTurns;
                    results[i].result.inchPoundsPerTurn = command.Result.ReplacementSpring.InchPoundsPerTurn;
                    results[i].result.cycles = command.Result.ReplacementSpring.Cycles;
                    results[i].result.length = command.Result.ReplacementSpring.Length;
                    results[i].result.minDoorWidth = command.Result.MinDoorWidth;
                }

                return results;
            }
        }

        vm.showReplacementSpringClick = function () {
            vm.showReplacementSpring = 'true';
            vm.showExistingSpring = 'false';
        }

        vm.showExistingSpringClick = function () {
            vm.showReplacementSpring = 'false';
            vm.showExistingSpring = 'true';
        }


        function initializeModel() {
            vm.activate = activate;
            vm.title = 'Replacement Options';
            vm.showExistingSpring = 'true';
            vm.showReplacementSpring = 'false';
            vm.existingSpring = {};
            vm.existingSpring.result = {};
            vm.existingSpring.input = {};
            vm.wireSizes = domainService.getWireSizes();
            vm.existingSpring.input.numberOfSprings = null;
            vm.existingSpring.input.wireSize = null;
            vm.existingSpring.input.insideDiameter = null;
            vm.existingSpring.input.length = null;
            vm.replacementSpring = {};
            vm.replacementSpring.input = {};
            vm.replacementSpring.input.numberOfSprings = null;
            vm.replacementSpring.input.insideDiameter = null;
            vm.replacementSpring.input.wireSize = 0.0;

            vm.replacementSpring.results = [];

            vm.calculationsComplete = false;
            vm.roundExistingSpring = { roundInchPoundsPerTurn: { decimals: 2, midpointRounding: 0 }, roundMaxTurns: { decimals: 2, midpointRounding: 0 }, roundCycles: { decimals: 2, midpointRounding: 0 } };
            vm.roundReplacementSpring = { roundLength: { decimals: 2, midpointRounding: 0 }, roundInchPoundsPerTurn: { decimals: 2, midpointRounding: 0 }, roundMaxTurns: { decimals: 2, midpointRounding: 0 }, roundCycles: { decimals: 2, midpointRounding: 0 } };
            vm.roundMinDoorWidth = { decimals: 2, midpointRounding: 0 };
        }
    }
}());