(function () {
    'use strict';

    var app = angular.module('app');

    // Collect the routes
    app.constant('routes', getRoutes());

    // Configure the routes and route resolvers
    app.config(['$routeProvider', 'routes', routeConfigurator]);
    function routeConfigurator($routeProvider, routes) {

        routes.forEach(function (r) {
            $routeProvider.when(r.url, r.config);
        });
        $routeProvider.otherwise({ redirectTo: '/home' });
    }

    // Define the routes 
    function getRoutes() {
        return [
             {
                 url: '/home',
                 config: {
                     title: 'accuspring',
                     pageTitle: 'Home',
                     templateUrl: 'app/home/home.html',
                     settings: {
                         showLeftNav: false
                         //nav: 3,
                         //content: '<i class="fa fa-arrows-h"></i> ACCUSPRING'
                     }
                 }
             }, {
                url: '/springconversion',
                config: {
                    title: 'springconversion',
                    pageTitle: 'Spring Conversion',
                    templateUrl: 'app/springconversion/springconversion.html',
                    settings: {
                        showLeftNav: true,
                        nav: 3,
                        content: '<i class="fa fa-arrows-h"></i> Spring Conversion'
                    }
                }
            }, {
                url: '/mismatchedsprings',
                config: {
                    title: 'mismatchedsprings',
                    pageTitle: 'Mismatched Springs',
                    templateUrl: 'app/mismatchedsprings/mismatchedsprings.html',
                    settings: {
                        nav: 4,
                        content: '<i class="fa fa-random"></i> Mismatched Springs'
                    }
                }
            }, {
                url: '/replacementoptions',
                config: {
                    title: 'replacementoptions',
                    pageTitle: 'Replacement Options',
                    templateUrl: 'app/replacementoptions/replacementoptions.html',
                    settings: {
                        nav: 5,
                        content: '<i class="fa fa-wrench"></i> Replacement Options'
                    }
                }
            }, {
                url: '/springsbyippt',
                config: {
                    title: 'springsbyippt',
                    pageTitle: 'Springs By IPPT',
                    templateUrl: 'app/springsbyippt/springsbyippt.html',
                    settings: {
                        nav: 6,
                        content: '<i class="fa fa-calculator"></i> Springs By IPPT'
                    }
                }
            }, {
                url: '/rollingsteel',
                config: {
                    title: 'rollingsteel',
                    pageTitle: 'Rolling Steel',
                    templateUrl: 'app/rollingsteel/rollingsteel.html',
                    settings: {
                        nav: 7,
                        content: '<i class="fa fa-dot-circle-o"></i> Rolling Steel'
                    }
                }
            }, {
                url: '/springengineeringsingle',
                config: {
                    title: 'springengineeringsingle',
                    pageTitle: 'Single',
                    templateUrl: 'app/springengineeringsingle/springengineeringsingle.html',
                    settings: {
                        nav: 8,
                        content: '<i class="fa fa-gear"></i> Engineering - Single'
                    }
                }
            }, {
                url: '/springengineeringduplex',
                config: {
                    title: 'springengineeringduplex',
                    pageTitle: 'Duplex',
                    templateUrl: 'app/springengineeringduplex/springengineeringduplex.html',
                    settings: {
                        nav: 9,
                        content: '<i class="fa fa-gears"></i> Engineering - Duplex'
                    }
                }
            }
        ];
    }
})();