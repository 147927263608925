(function () {
    'use strict';

    angular
        .module('app')
        .directive('springSelect', function () {
            return {
                restrict: 'E',
                scope: {
                    selectedvalue: '=',
                    datasource: '='
                },
                templateUrl: '/app/_directives/select/springselect.html'
            };
        });
}());