(function () {
    'use strict';

    angular
    .module('app')
    .directive('springMaxValidator', springMaxValidator);

    springMaxValidator.$inject = ['common']
    function springMaxValidator(common) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, el, attr, ctrl) {
                ctrl.$validators.springMaxValidator = function (modelValue, viewValue) {
                    var maxValue = Number(attr.springMaxValue);
                    if (isNaN(maxValue))
                        return false;
                    if (ctrl.$isEmpty(modelValue) || !maxValue) {
                        // consider empty models to be valid
                        return true;
                    }
                    if (modelValue <= maxValue) {
                        return true;
                    }
                    var attributeName =  attr && attr.id ? attr.id : 'value';
                    var message = modelValue + ' exceeds the maximum ' + attributeName + ' of ' + maxValue;
                        common.logger.logWarning(message, '', '', true);
                        return false;               
                }

            }
        }
    }

}());