(function () {
    'use strict';

    var app = angular.module('app', [
        // Angular modules 
        'ngAnimate',        // animations
        'ngRoute',          // routing
        'ngSanitize',       // sanitizes html bindings (ex: sidebar.js)

        // Custom modules 
        'common',           // common functions, logger, spinner
        'common.bootstrap', // bootstrap dialog wrapper functions

        // 3rd Party Modules
        'ui.bootstrap'      // ui-bootstrap (ex: carousel, pagination, dialog)
    ]);

    // Handle routing errors and success events
    app.run(['$route', '$rootScope', '$window', '$location', '$routeParams', 'domainService', function ($route, $rootScope, $window, $location, $routeParams, domainService) {
        // Include $route to kick start the router.
        domainService.prime();

        //anaytics code
        $rootScope.$on("$routeChangeSuccess", function(event, currentRoute, previousRoute) {
            var pageTitle = currentRoute.pageTitle;
            $rootScope.title = pageTitle;
            $window.ga('send', 'pageview', { page: $location.url(), title: pageTitle });
        });
    }]);
})();