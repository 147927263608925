(function () {
  'use strict';

  var app = angular.module('app');

  // Configure Toastr
  toastr.options.timeOut = 4000;
  toastr.options.positionClass = 'toast-top-right';
  toastr.options.closeButton = true;
  toastr.options.timeOut = 0;
  toastr.options.tapToDismiss = true;
  toastr.options.extendedTimeOut = 0;

  // For use with the HotTowel-Angular-Breeze add-on that uses Breeze
  var remoteServiceName = 'breeze/Breeze';

  var events = {
    controllerActivateSuccess: 'controller.activateSuccess',
    spinnerToggle: 'spinner.toggle',
  };

  var apiHost = '';
  if (window.location.hostname === 'localhost')
    apiHost = 'http://ec2-107-22-8-205.compute-1.amazonaws.com';
  // apiHost = '//ec2-18-212-18-182.compute-1.amazonaws.com';
  // apiHost = '//thewebineer.com/z_accuspring';

  var config = {
    appErrorPrefix: '[Accuspring Calculator] ', //Configure the exceptionHandler decorator
    docTitle: 'Accuspring Calculator: ',
    events: events,
    remoteServiceName: remoteServiceName,
    version: '2.1.0',
    debounceDefault: 500,
    apiHost: apiHost,
  };

  app.value('config', config);

  app.config([
    '$logProvider',
    function ($logProvider) {
      // turn debugging off/on (no info or warn)
      if ($logProvider.debugEnabled) {
        $logProvider.debugEnabled(true);
      }
    },
  ]);

  //#region Configure the common services via commonConfig
  app.config([
    'commonConfigProvider',
    function (cfg) {
      cfg.config.controllerActivateSuccessEvent =
        config.events.controllerActivateSuccess;
      cfg.config.spinnerToggleEvent = config.events.spinnerToggle;
    },
  ]);
  //#endregion
})();
