(function () {
    'use strict';

    angular
        .module('app')
        .directive('springResult', springResult);

    function springResult() {
        return {
            restrict: 'E',
            scope: {
                dataresult: '@'
            },
            templateUrl: '/app/_directives/springresult.html'
        }

    }

}());