(function () {
    'use strict';

    var controllerId = 'springconversion';

    angular
        .module('app')
        .controller(controllerId, springconversion);

    springconversion.$inject = ['$scope', 'common', 'datacontext', 'domainService'];
    function springconversion($scope, common, datacontext, domainService) {
        var vm = this;
        var getLogFn = common.logger.getLogFn;
        var log = getLogFn(controllerId);

        initializeModel();
        activate();

        function activate() {
            var promises = [];
            common.activateController(promises, controllerId)
                .then(function () {
                    log('Activated Spring Conversion Calculator View');
                    common.logger.logWarning("Please verify that there is enough shaft space for the designed spring(s).",'','',
                            true);
                });
        }

        // watch existing spring inputs to do calculation
        $scope.$watchCollection('vm.existingSpring.input', function (newExistingSpring, oldExistingSpring) {
            if (areExistingSpringInputsComplete(newExistingSpring, oldExistingSpring)) {
                calculateExistingSpring();
                if (areReplacementSpringInputsComplete(vm.replacementSpring.input)) {
                    calculateMatchedSprings();
                }
            }
        });

        // watch replacement spring inputs to do calculation
        $scope.$watchCollection('vm.replacementSpring.input', function (newReplacementSpring, oldReplacementSpring) {
            if (areReplacementSpringInputsComplete(newReplacementSpring)) {
                calculateMatchedSprings();
            }
        });

        function areExistingSpringInputsComplete(newSpring) {
            if (common.areInputsComplete(newSpring)) {
                return true;
            } else {
                common.clearResults(vm.existingSpring.result);
                common.clearResults(vm.replacementSpring.result);
                vm.calculationsComplete = false;
                return false;
            }
            return false;
        }

        function areReplacementSpringInputsComplete(newSpring, oldSpring) {
            if (common.areInputsComplete(newSpring)) {
                return (newSpring != oldSpring);
            } else {
                common.clearResults(vm.replacementSpring.result);
                vm.calculationsComplete = false;
                return false;
            }
            return false;
        }

        function calculateExistingSpring() {
            var commands = [];
            var command = {};
            var contentType = 'Spring.Application.Commands.CommandCalculateMatchedSpring';

            command.existingSpring = vm.existingSpring.input;
            command.roundExistingSpring = vm.roundExistingSpring;
            commands.push({ ContentType: contentType, Command: command });
            var transactionId = common.generateGuid();

            return datacontext.calculateCommands(transactionId, commands).then(function (data) {
                mapData(data);
            });

            function mapData(data) {
                var command = data.Commands[0].Command;
                vm.existingSpring.result.maxTurns = command.Result.ExistingSpring.MaxTurns;
                vm.existingSpring.result.inchPoundsPerTurn = command.Result.ExistingSpring.InchPoundsPerTurn;
                vm.existingSpring.result.cycles = command.Result.ExistingSpring.Cycles;
            }
        }

        function calculateMatchedSprings() {
            var commands = [];
            var contentType = 'Spring.Application.Commands.CommandCalculateMatchedSprings';
            var transactionId = common.generateGuid();

            var command = {};
            command.existingSpring = vm.existingSpring.input;
            command.roundExistingSpring = vm.roundExistingSpring;
            command.replacementSpring = vm.replacementSpring.input;
            command.roundReplacementSpring = vm.roundReplacementSpring;
            command.roundMinDoorWidth = vm.roundMinDoorWidth;
            commands.push({ ContentType: contentType, Command: command });

            return datacontext.calculateCommands(transactionId, commands).then(function (data) {
                mapData(data);

                vm.calculationsComplete = true;
            });


            function mapData(data) {
                var command = data.Commands[0].Command;
                vm.existingSpring.result.maxTurns = command.Result.ExistingSpring.MaxTurns;
                vm.existingSpring.result.inchPoundsPerTurn = command.Result.ExistingSpring.InchPoundsPerTurn;
                vm.existingSpring.result.cycles = command.Result.ExistingSpring.Cycles;
                vm.replacementSpring.result.maxTurns = command.Result.ReplacementSpring.MaxTurns;
                vm.replacementSpring.result.inchPoundsPerTurn = command.Result.ReplacementSpring.InchPoundsPerTurn;
                vm.replacementSpring.result.cycles = command.Result.ReplacementSpring.Cycles;
                vm.replacementSpring.result.length = command.Result.ReplacementSpring.Length;
                vm.replacementSpring.result.minDoorWidth = command.Result.MinDoorWidth;
            }
        }

        vm.showReplacementSpringClick = function () {
            vm.showReplacementSpring = 'true';
            vm.showExistingSpring = 'false';
        }

        vm.showExistingSpringClick = function () {
            vm.showReplacementSpring = 'false';
            vm.showExistingSpring = 'true';
        }

        function initializeModel() {
            vm.activate = activate;
            vm.title = 'Spring Conversion';
            vm.showExistingSpring = 'true';
            vm.showReplacementSpring = 'false';
            vm.existingSpring = {};
            vm.existingSpring.result = {};
            vm.existingSpring.input = {};
            vm.existingSpring.input.numberOfSprings = null;
            vm.existingSpring.input.wireSize = null;
            vm.existingSpring.input.insideDiameter = null;
            vm.existingSpring.input.length = null;
            vm.replacementSpring = {};
            vm.replacementSpring.result = {};
            vm.replacementSpring.input = {};
            vm.replacementSpring.input.numberOfSprings = null;
            vm.replacementSpring.input.wireSize = null;
            vm.replacementSpring.input.insideDiameter = null;
            vm.calculationsComplete = false;
            vm.roundExistingSpring = { roundInchPoundsPerTurn: { decimals: 2, midpointRounding: 0 }, roundMaxTurns: { decimals: 2, midpointRounding: 0 }, roundCycles: { decimals: 2, midpointRounding: 0 } };
            vm.roundReplacementSpring = { roundLength: { decimals: 2, midpointRounding: 0 }, roundInchPoundsPerTurn: { decimals: 2, midpointRounding: 0 }, roundMaxTurns: { decimals: 2, midpointRounding: 0 }, roundCycles: { decimals: 2, midpointRounding: 0 } };
            vm.roundMinDoorWidth = { decimals: 2, midpointRounding: 0 };
        }
    }
})();
