(function () {
    'use strict';

    var controllerId = 'home';

    angular
        .module('app')
        .controller(controllerId, home);

    home.$inject = ['$scope', 'common'];
    function home($scope, common) {
        var vm = this;
        var getLogFn = common.logger.getLogFn;
        var log = getLogFn(controllerId);

        activate();

        function activate() {
            var promises = [];
            common.activateController(promises, controllerId)
                .then(function () {
                    log('Activated Home Page');
                });
        }
    }
})();
