(function () {
    'use strict';

    var serviceId = 'mockdatacontext';
    angular.module('app').factory(serviceId, ['$http', 'common', mockdatacontext]);

    function mockdatacontext($http, common) {
        var $q = common.$q;

        var service = {
            getPeople: getPeople,
            getMessageCount: getMessageCount,
            getWireSizes: getWireSizes,
            getInsideDiameters: getInsideDiameters,
            calculateExistingSpringConversion: calculateExistingSpringConversion
        };

        return service;

        function getMessageCount() { return $q.when(72); }

        function getPeople() {
            var people = [
                { firstName: 'John', lastName: 'Papa', age: 25, location: 'Florida' },
                { firstName: 'Ward', lastName: 'Bell', age: 31, location: 'California' },
                { firstName: 'Colleen', lastName: 'Jones', age: 21, location: 'New York' },
                { firstName: 'Madelyn', lastName: 'Green', age: 18, location: 'North Dakota' },
                { firstName: 'Ella', lastName: 'Jobs', age: 18, location: 'South Dakota' },
                { firstName: 'Landon', lastName: 'Gates', age: 11, location: 'South Carolina' },
                { firstName: 'Haley', lastName: 'Guthrie', age: 35, location: 'Wyoming' }
            ];
            return $q.when(people);
        }

        function getWireSizes() {
            var wireSizes = [0.1620
                            , 0.1770
                            , 0.1875
                            , 0.1920
                            , 0.2000
                            , 0.2070
                            , 0.2187
                            , 0.2253
                            , 0.2343
                            , 0.2437
                            , 0.2500];
            return $q.when(wireSizes);
        }

        function getInsideDiameters() {
            var insideDiameters = [1.5
                                    , 1.59375
                                    , 1.625
                                    , 1.75
                                    , 1.8125
                                    , 2
                                    , 2.1875
                                    , 2.25
                                    , 2.4375
                                    , 2.5
                                    , 2.625
                                    , 2.75
                                    , 2.78125];
            return $q.when(insideDiameters);
        }


        function calculateExistingSpringConversion(vm) {
            var Result = {};
            Result.ExistingSpring = {};
            Result.ReplacementSpring = {};
            Result.ExistingSpring.InchPoundsPerTurn = 7.1234;
            Result.ExistingSpring.MaxTurns = 8.4567;
            Result.ExistingSpring.Cycles = 9.9874;
            Result.ReplacementSpring.InchPoundsPerTurn = 4.5612;
            Result.ReplacementSpring.MaxTurns = 5.5612;
            Result.ReplacementSpring.Cycles = 6.5612;
            return $q.when(Result);
        }

    }
})();