(function () {
    'use strict';

    angular
        .module('app')
        .directive('springFormGroup', function () {
            return {
                restrict: 'E',
                scope: {
                    datalabel: '@'
                },
                transclude: true,
                templateUrl: '/app/_directives/springformgroup.html'
            };
        });
}());