(function () {
    'use strict';

    var controllerId = 'springsbyippt';

    angular
        .module('app')
        .controller(controllerId, springsbyippt);

    springsbyippt.$inject = ['$scope', 'common', 'datacontext', 'domainService'];
    function springsbyippt($scope, common, datacontext, domainService) {
        var vm = this;
        var getLogFn = common.logger.getLogFn;
        var log = getLogFn(controllerId);


        initializeModel();
        activate();

        function activate() {
            var promises = [];
            common.activateController(promises, controllerId)
                .then(function () {
                    log('Activated Springs By IPPT Calculator View');
                    common.logger.logWarning("Please verify that there is enough shaft space for the designed spring(s).", '', '', true);
                });
        }

        // watch existing spring inputs to do calculation
        $scope.$watchCollection('vm.existingSpring.input', function (newExistingSpring, oldExistingSpring) {
            if (areExistingSpringInputsComplete(newExistingSpring, oldExistingSpring)) {
                if (areReplacementSpringInputsComplete(vm.replacementSpring.input)) {
                    calculateSpringIppt();
                }
            }
        });

        // watch replacement spring inputs to do calculation
        $scope.$watchCollection('vm.replacementSpring.input', function (newReplacementSpring, oldReplacementSpring) {
            if (areReplacementSpringInputsComplete(newReplacementSpring)) {
                calculateSpringIppt();
            }
        });

        function areExistingSpringInputsComplete(newSpring) {
            if (common.areInputsComplete(newSpring)) {
                return true;
            } else {
                common.clearResults(vm.existingSpring.result);
                common.clearResults(vm.replacementSpring.result);
                vm.calculationsComplete = false;
                return false;
            }
            return false;
        }

        function areReplacementSpringInputsComplete(newSpring, oldSpring) {
            if (common.areInputsComplete(newSpring)) {
                return (newSpring != oldSpring);
            } else {
                common.clearResults(vm.replacementSpring.result);
                vm.calculationsComplete = false;
                return false;
            }
            return false;
        }


        function calculateSpringIppt() {
            var commands = [];
            var command = {};
            command.ippt = vm.existingSpring.input.ippt;
            command.turnsRequired = vm.existingSpring.input.turnsRequired;
            command.insideDiameter = vm.existingSpring.input.insideDiameter;
            command.cycles = vm.replacementSpring.input.cycles;
            command.roundMip = vm.roundMip;
            command.roundMtsOfWire = vm.roundMtsOfWire;
            command.roundPercentMts = vm.roundPercentMts;
            command.roundWireSize = vm.roundWireSize;
            command.roundInsideDiameter = vm.roundInsideDiameter;
            command.roundLength = vm.roundLength;
            command.roundWeight = vm.roundWeight;
            command.roundTotalCoils = vm.roundTotalCoils;
            commands.push({ ContentType: vm.contentType, Command: command });
            var transactionId = common.generateGuid();

            return datacontext.calculateCommands(transactionId, commands).then(function (data) {
                mapData(data);

                vm.calculationsComplete = true;
            });


            function mapData(data) {
                var result = data.Commands[0].Command.Result;
                vm.existingSpring.result.mip = result.MIP;
                vm.existingSpring.result.mtsOfWire = result.MTSofWire;
                vm.existingSpring.result.percentMts = result.PercentMTS;
                vm.replacementSpring.result.wireSize = result.WireSize;
                vm.replacementSpring.result.length = result.Length;
                vm.replacementSpring.result.weight = result.Weight;
                vm.replacementSpring.result.totalCoils = result.TotalCoils;
                vm.replacementSpring.result.insideDiameter = result.InsideDiameter;
            };
        }

        vm.showReplacementSpringClick = function () {
            vm.showReplacementSpring = 'true';
            vm.showExistingSpring = 'false';
        }

        vm.showExistingSpringClick = function () {
            vm.showReplacementSpring = 'false';
            vm.showExistingSpring = 'true';
        }

        function initializeModel() {
            vm.activate = activate;
            vm.title = 'Springs By IPPT';
            vm.insideDiameters = [];
            vm.showExistingSpring = 'true';
            vm.showReplacementSpring = 'false';
            vm.existingSpring = {};
            vm.existingSpring.result = {};
            vm.existingSpring.input = {};
            vm.existingSpring.input.ippt = null;
            vm.existingSpring.input.turnsRequired = null;
            vm.existingSpring.input.insideDiameter = null;
            vm.replacementSpring = {};
            vm.replacementSpring.result = {};
            vm.replacementSpring.input = {};
            vm.replacementSpring.input.cycles = null;
            vm.calculationsComplete = false;
            vm.roundMip = { decimals: 2, midpointRounding: 0 };
            vm.roundMtsOfWire = { decimals: 0, midpointRounding: 0 };
            vm.roundPercentMts = { decimals: 2, midpointRounding: 0 };
            vm.roundWireSize = { decimals: 4, midpointRounding: 0 };
            vm.roundInsideDiameter = { decimals: 3, midpointRounding: 0 };
            vm.roundLength = { decimals: 2, midpointRounding: 0 };
            vm.roundWeight = { decimals: 2, midpointRounding: 0 };
            vm.roundTotalCoils = { decimals: 2, midpointRounding: 0 };
            vm.contentType = 'Spring.Application.Commands.CommandCalculateSpringIPPT';
        }
    }
})();
